@import (reference) './base/base.less';
@import (reference) './base/variables.less';

.sidebar {
  border-right: 1px solid #e8e8e8;

  .menu {
    background-color: inherit;

    .menu_item {
      font-family: Kufam;
      font-style: normal;
      font-weight: bold;
      font-size: 15px;
    }
  }

  &.collapsed {
    .logo {
      margin: 5px;

      img {
        width: 65px;
        padding: 3px;
      }
    }

    .menu {
      border: none;
    }

    .menu_item {
      color: #f3f3f3;
    }
  }

  &.not_collapsed {
    .logo {
      margin-top: 25px;
      margin-bottom: 0px;
      padding: 10px;

      img {
        width: 210px;
        height: 38px;
      }
    }

    .menu {
      border-right: 1px solid #e8e8e8;
    }

    .menu_item {
      color: @dark-blue;
    }
  }
}

.input_arrimo {
  border: 2px solid #f2f2f2 !important;
  box-sizing: border-box !important;
  border-radius: 10px !important;
  font-family: Kufam;
  font-style: normal;
  font-weight: normal;
  font-size: 12px !important;
  line-height: 16px !important;
  display: flex !important;
}

.divider {
  margin-top: 10px;
}

@primary-color: black;@card-padding-base: 15px;@layout-sider-background: #fff;@menu-bg: #fff;@border-radius-base: 2px;@collapse-content-bg: #fff;@collapse-header-bg: #fff;@collapse-panel-border-radius: 10px;@layout-header-background: #f7f9fa;@heading-color: black;@label-color: #000000a6;@layout-header-height: 50px;@form-item-margin-bottom: 18px;@form-vertical-label-padding: 0 0 4px;@table-header-color: rgba(0,0,0,0.65);@tabs-vertical-margin: 0;@font-family: Kufam;