.ant-select-selection.ant-select-selection--single,
.ant-input-number {
  border-radius: 10px;
  border: 2px solid #f2f2f2;
  width: 100% !important;
}

.ant-select,
.ant-select.ant-select-enabled {
  display: block;
}

.ant-btn {
  white-space: normal;
  min-height: 3.25rem;
}

.ant-collapse .ant-collapse-content-box {
  padding-bottom: 16px !important;
}

.ant-table-thead
  > tr.ant-table-row-hover:not(.ant-table-expanded-row):not(
    .ant-table-row-selected
  )
  > td,
.ant-table-tbody
  > tr.ant-table-row-hover:not(.ant-table-expanded-row):not(
    .ant-table-row-selected
  )
  > td,
.ant-table-thead
  > tr:hover:not(.ant-table-expanded-row):not(.ant-table-row-selected)
  > td,
.ant-table-tbody
  > tr:hover:not(.ant-table-expanded-row):not(.ant-table-row-selected)
  > td {
  background-color: #ededed;
}

/* Fullcalendar */
.fc .fc-scrollgrid-section table {
  height: 100% !important;
}
